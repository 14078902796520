import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ClickAwayListener, Grid, List, ListItemButton, ListItemText, Paper, Popper, Typography, useMediaQuery } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
// project import
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import useConfig from 'hooks/useConfig';

// ==============================|| HEADER CONTENT - LOCALIZATION ||============================== //

const Localization = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const { i18n, onChangeLocalization } = useConfig();

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (lang) => {
        onChangeLocalization(lang);
        setOpen(false);
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <IconButton
                color="secondary"
                variant="light"
                sx={{ color: 'text.primary' }}
                aria-label="open localization"
                ref={anchorRef}
                aria-controls={open ? 'localization-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <LanguageIcon />
            </IconButton>
            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 0 : 0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="grow" position={matchesXs ? 'top-right' : 'top'} in={open} {...TransitionProps}>
                        <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <List
                                    component="nav"
                                    sx={{
                                        p: 0,
                                        width: '100%',
                                        minWidth: 200,
                                        maxWidth: 290,
                                        bgcolor: theme.palette.background.paper,
                                        borderRadius: 0.5,
                                        [theme.breakpoints.down('md')]: {
                                            maxWidth: 250
                                        }
                                    }}
                                >
                                    <ListItemButton selected={i18n === 'en'} onClick={() => handleListItemClick('en')}>
                                        <ListItemText
                                            primary={
                                                <Grid container>
                                                    <Typography color="textPrimary">English</Typography>
                                                    {/* <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                                                        (UK)🇺🇸
                                                    </Typography> */}
                                                </Grid>
                                            }
                                        />
                                    </ListItemButton>
                                    <ListItemButton selected={i18n === 'de'} onClick={() => handleListItemClick('de')}>
                                        <ListItemText
                                            primary={
                                                <Grid container>
                                                    <Typography color="textPrimary">Deutsch</Typography>
                                                    {/* <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                                                        (German)🇩🇪
                                                    </Typography> */}
                                                </Grid>
                                            }
                                        />
                                    </ListItemButton>
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Localization;