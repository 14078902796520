const jwt = require('jsonwebtoken');
const CryptoJS = require('crypto-js');

const createToken = (data) => {
    const privateKey = process.env.REACT_APP_SECRET_KEY;
    const paramsOption = {
      algorithm: 'RS256',
      expiresIn: '2 days'
    };
    const formattedPrivateKey = formatPrivateKey(privateKey);
    const token = jwt.sign(data, formattedPrivateKey, paramsOption);
    return token;
  };
  const formatPrivateKey = (privateKey) => {
    const begin = '-----BEGIN RSA PRIVATE KEY-----\n';
    const end = '\n-----END RSA PRIVATE KEY-----';
    // Use a regular expression to insert newlines every 64 characters
    const formattedKey = privateKey.replace(/(.{64})/g, '$1\n');
  
    return `${begin}${formattedKey}${end}`;
  };
const encrypt = (data) => {
    const ivKey = process.env.REACT_APP_ENCRYPTION_KEY;
    if (ivKey) {  
      const binaryEncryptionKey = CryptoJS.enc.Base64.parse(ivKey);
      const cipher = CryptoJS.AES.encrypt(data, binaryEncryptionKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      return cipher.toString();
    }
  };
  
const decrypt = (encryptedData) => {
    const ivKey = process.env.REACT_APP_ENCRYPTION_KEY;
    if (ivKey) {
      const binaryEncryptionKey = CryptoJS.enc.Base64.parse(ivKey);
      const bytes = CryptoJS.AES.decrypt(encryptedData, binaryEncryptionKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      return bytes.toString(CryptoJS.enc.Utf8);
    }
  };

  
 const formatDateByLocale = (date, locale) => {
  if (typeof date !== 'string') {
    console.error('Invalid input: expected a string in DD.MM.YYYY or DD.MM.YYYY HH:mm:ss format');
    return date;
  }

  // Updated regex to match both 'DD.MM.YYYY' and 'DD.MM.YYYY HH:mm:ss'
  const dateRegex = /^(\d{2})\.(\d{2})\.(\d{4})(?:\s(\d{2}):(\d{2}):(\d{2}))?$/;
  const match = date.match(dateRegex);

  if (!match) {
    console.error('Invalid date format. Expected DD.MM.YYYY or DD.MM.YYYY HH:mm:ss');
    return date;
  }

  const [, day, month, year, hours = '00', minutes = '00', seconds = '00'] = match;

  // Create a Date object (note: months are 0-indexed in JavaScript)
  const dateObj = new Date(year, month - 1, day, hours, minutes, seconds);

  // Check if the date is valid
  if (isNaN(dateObj.getTime())) {
    console.error('Invalid date');
    return date;
  }

  // Format the date according to the specified locale
  if (locale === 'en') {
    // English format: MM/DD/YYYY hh:mm:ss AM/PM
    const ampm = dateObj.getHours() >= 12 ? 'PM' : 'AM';
    const formattedHours = dateObj.getHours() % 12 || 12; // Convert 24h to 12h format
    return `${(dateObj.getMonth() + 1).toString().padStart(2, '0')}/${dateObj.getDate().toString().padStart(2, '0')}/${dateObj.getFullYear()} ${formattedHours.toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}:${dateObj.getSeconds().toString().padStart(2, '0')} ${ampm}`;
  } else {
    // German format: DD.MM.YYYY HH:mm:ss
    return `${dateObj.getDate().toString().padStart(2, '0')}.${(dateObj.getMonth() + 1).toString().padStart(2, '0')}.${dateObj.getFullYear()} ${dateObj.getHours().toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}:${dateObj.getSeconds().toString().padStart(2, '0')}`;
  }
};



 function clearCookieStorage(hotReload = false) {
    
    (async() => {
      let cookies = document.cookie.split("; ");
      for (let c = 0; c < cookies.length; c++) {
        let d = window.location.hostname.split(".");
        while (d.length > 0) {
          let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
          let p = window.location.pathname.split('/');
          document.cookie = cookieBase + '/';
          while (p.length > 0) {
            document.cookie = cookieBase + p.join('/');
            p.pop();
          }
          d.shift();
        }
      }
      const host = window.location.host;
      const parts = host.split(".");
      parts.shift();   
      await redirectToLogin();    
      localStorage.clear();
    })();
    if (hotReload)
      window.location.reload();
  }

 

  const redirectToLogin = async()=>{
    let redirectUrl = localStorage.getItem('redirectLogin');
    let encryptedData =await encrypt(window.location.href);
   
    let result = redirectUrl.replace(/"/g, ''); 

    await window.location.assign(result+ 'auth/login/' + encodeURIComponent(encryptedData));
    
  }

module.exports = {
  createToken,
  encrypt,
  decrypt,
  formatDateByLocale,
  clearCookieStorage
};
